<template>
  <div id="support" class="py-24 bg-[#f3f4f6]">
    <div class="mx-auto">
      <div class="container">
        <div class="text-center">
          <h2
            class="mb-12 section-heading wow fadeInDown"
            data-wow-delay="0.3s"
          >
            Support
          </h2>
        </div>
        <div class="grid grid-cols-1 gap-16 md:grid-cols-3">
          <div
            class="relative z-20 h-[250px] overflow-hidden rounded-lg"
            v-for="(video, index) in supports"
            :key="index"
          >
            <div class="absolute top-0 left-0 w-full h-full">
              <!-- <img
                src="../../assets/bg-hero.jpg"
                alt="People watching video of sungodlwater"
                title="People watching video of sungodlwater"
              /> -->
              <video controls class="object-cover object-center w-full h-full">
                <source
                  :src="
                    getBaseUrl() + video.attributes.video.data.attributes.url
                  "
                  type="video/mp4"
                />
              </video>
            </div>
            <div
              class="absolute top-0 left-0 z-10 flex items-center justify-center w-full h-full bg-opacity-60 bg-primary-gold"
            >
              <a
                @click="setVideo(video)"
                class="absolute z-20 flex items-center justify-center bg-white rounded-full w-14 h-14 text-primary"
              >
                <!-- <span
                  class="absolute top-0 right-0 z-[-1] h-full w-full animate-ping rounded-full bg-white bg-opacity-20 delay-300 duration-1000"
                >
                </span> -->
                <svg
                  width="23"
                  height="27"
                  viewBox="0 0 23 27"
                  class="fill-current"
                >
                  <path
                    d="M22.5 12.634C23.1667 13.0189 23.1667 13.9811 22.5 14.366L2.25 26.0574C1.58333 26.4423 0.750001 25.9611 0.750001 25.1913L0.750002 1.80866C0.750002 1.03886 1.58334 0.557731 2.25 0.942631L22.5 12.634Z"
                  />
                </svg>
              </a>
            </div>

            <div>
              <span class="absolute z-40 top-4 left-4">
                <div
                  class="px-4 text-lg font-medium leading-snug text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-violet-500"
                >
                  {{ video.attributes.title }}
                </div>
              </span>
              <!-- <span class="absolute z-40 bottom-4 right-4">
                <svg
                  width="50"
                  height="79"
                  viewBox="0 0 50 79"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="47.7119"
                    cy="76.9617"
                    r="1.74121"
                    transform="rotate(180 47.7119 76.9617)"
                    fill="white"
                  />
                  <circle
                    cx="47.7119"
                    cy="61.6385"
                    r="1.74121"
                    transform="rotate(180 47.7119 61.6385)"
                    fill="white"
                  />
                  <circle
                    cx="47.7119"
                    cy="46.3163"
                    r="1.74121"
                    transform="rotate(180 47.7119 46.3163)"
                    fill="white"
                  />
                  <circle
                    cx="47.7119"
                    cy="16.7159"
                    r="1.74121"
                    transform="rotate(180 47.7119 16.7159)"
                    fill="white"
                  />
                  <circle
                    cx="47.7119"
                    cy="31.3421"
                    r="1.74121"
                    transform="rotate(180 47.7119 31.3421)"
                    fill="white"
                  />
                  <circle
                    cx="47.7119"
                    cy="1.74121"
                    r="1.74121"
                    transform="rotate(180 47.7119 1.74121)"
                    fill="white"
                  />
                  <circle
                    cx="32.3916"
                    cy="76.9617"
                    r="1.74121"
                    transform="rotate(180 32.3916 76.9617)"
                    fill="white"
                  />
                  <circle
                    cx="32.3877"
                    cy="61.6384"
                    r="1.74121"
                    transform="rotate(180 32.3877 61.6384)"
                    fill="white"
                  />
                  <circle
                    cx="32.3916"
                    cy="46.3162"
                    r="1.74121"
                    transform="rotate(180 32.3916 46.3162)"
                    fill="white"
                  />
                  <circle
                    cx="32.3916"
                    cy="16.7161"
                    r="1.74121"
                    transform="rotate(180 32.3916 16.7161)"
                    fill="white"
                  />
                  <circle
                    cx="32.3877"
                    cy="31.342"
                    r="1.74121"
                    transform="rotate(180 32.3877 31.342)"
                    fill="white"
                  />
                  <circle
                    cx="32.3916"
                    cy="1.74145"
                    r="1.74121"
                    transform="rotate(180 32.3916 1.74145)"
                    fill="white"
                  />
                  <circle
                    cx="17.0674"
                    cy="76.9617"
                    r="1.74121"
                    transform="rotate(180 17.0674 76.9617)"
                    fill="white"
                  />
                  <circle
                    cx="17.0674"
                    cy="61.6384"
                    r="1.74121"
                    transform="rotate(180 17.0674 61.6384)"
                    fill="white"
                  />
                  <circle
                    cx="17.0674"
                    cy="46.3162"
                    r="1.74121"
                    transform="rotate(180 17.0674 46.3162)"
                    fill="white"
                  />
                  <circle
                    cx="17.0674"
                    cy="16.7161"
                    r="1.74121"
                    transform="rotate(180 17.0674 16.7161)"
                    fill="white"
                  />
                  <circle
                    cx="17.0674"
                    cy="31.342"
                    r="1.74121"
                    transform="rotate(180 17.0674 31.342)"
                    fill="white"
                  />
                  <circle
                    cx="17.0674"
                    cy="1.74145"
                    r="1.74121"
                    transform="rotate(180 17.0674 1.74145)"
                    fill="white"
                  />
                  <circle
                    cx="1.74316"
                    cy="76.9617"
                    r="1.74121"
                    transform="rotate(180 1.74316 76.9617)"
                    fill="white"
                  />
                  <circle
                    cx="1.74316"
                    cy="61.6384"
                    r="1.74121"
                    transform="rotate(180 1.74316 61.6384)"
                    fill="white"
                  />
                  <circle
                    cx="1.74316"
                    cy="46.3162"
                    r="1.74121"
                    transform="rotate(180 1.74316 46.3162)"
                    fill="white"
                  />
                  <circle
                    cx="1.74316"
                    cy="16.7161"
                    r="1.74121"
                    transform="rotate(180 1.74316 16.7161)"
                    fill="white"
                  />
                  <circle
                    cx="1.74316"
                    cy="31.342"
                    r="1.74121"
                    transform="rotate(180 1.74316 31.342)"
                    fill="white"
                  />
                  <circle
                    cx="1.74316"
                    cy="1.74145"
                    r="1.74121"
                    transform="rotate(180 1.74316 1.74145)"
                    fill="white"
                  />
                </svg>
              </span> -->
            </div>
          </div>
        </div>
        <VideoPage :show="showVideo" :video="video" @close="showVideo = false">
        </VideoPage>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { baseUrl } from "../../Api";

import VideoPage from "@/components/modal/VideoPage.vue";
export default {
  components: {
    VideoPage,
  },
  data() {
    return {
      showVideo: false,
      video: null,
    };
  },
  created() {
    this.fetchSupport();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    setVideo(video) {
      (this.showVideo = true), (this.video = video);
    },
    ...mapActions(["fetchSupport"]),
  },
  computed: {
    ...mapState(["supports"]),
  },
};
</script>

<style lang="scss" scoped></style>
