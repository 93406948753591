<template>
  <header class="bg-[#f3f4f6]">
    <div
      class="fixed top-0 left-0 z-30 w-full duration-300 navigation"
      :class="{
        sticky: !view.atTopOfPage,
      }"
    >
      <div class="container">
        <nav
          class="relative flex items-center justify-between py-4 duration-300 md:py-2"
        >
          <a href="/" class="nav-item">
            <img
              src="../../assets/logo.png"
              alt="habariDoc logo"
              class="w-28"
            />
          </a>
          <div class="lg:hidden">
            <svg
              @click="toggleView"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-8 h-8 text-gray-600 hover:text-gray-400"
            >
              <path
                v-if="!isOpen"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
              <path
                v-if="isOpen"
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </div>
          <div
            class="left-0 z-20 hidden w-full px-5 py-3 duration-300 bg-white shadow lg:block mt-full lg:static lg:bg-transparent lg:shadow-none"
          >
            <ul class="items-center justify-center mr-auto lg:flex">
              <li class="nav-item">
                <a class="page-scroll active" href="#hero-area">Home</a>
              </li>
              <li class="nav-item">
                <a class="page-scroll" href="#services">Services</a>
              </li>
              <li class="nav-item">
                <a class="page-scroll" href="#feature">Features</a>
              </li>
              <li class="nav-item">
                <a class="page-scroll" href="#partner">Partners</a>
              </li>
              <li class="nav-item">
                <a class="page-scroll" href="#support">Support</a>
              </li>
            </ul>
          </div>
          <div class="hidden space-x-5 header-btn lg:block">
            <a
              class="px-10 py-3 text-blue-600 duration-300 border border-blue-600 rounded-full hover:bg-blue-600 hover:text-white"
              href="#feature"
            >
              Login
            </a>
          </div>
        </nav>
        <div
          :class="isOpen ? 'block' : 'hidden'"
          class="absolute left-0 z-20 w-full px-10 py-3 duration-300 bg-white shadow lg:hidden mt-full lg:static"
        >
          <ul class="items-center justify-center mr-auto lg:flex">
            <li class="nav-item">
              <a class="page-scroll active" href="#hero-area">Home</a>
            </li>
            <li class="nav-item">
              <a class="page-scroll" href="#services">Services</a>
            </li>
            <li class="nav-item">
              <a class="page-scroll" href="#feature">Features</a>
            </li>
            <li class="nav-item">
              <a class="page-scroll" href="#partner">Partners</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: "nav-index",
  data() {
    return {
      isOpen: false,
      view: {
        atTopOfPage: true,
      },
    };
  },

  beforeMount() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    toggleView() {
      this.isOpen = !this.isOpen;
    },
    // the function to call when the user scrolls, added as a method
    handleScroll() {
      // when the user scrolls, check the pageYOffset
      if (window.pageYOffset > 0) {
        // user is scrolled
        if (this.view.atTopOfPage) this.view.atTopOfPage = false;
      } else {
        // user is at top of page
        if (!this.view.atTopOfPage) this.view.atTopOfPage = true;
      }
    },
  },
};
</script>

<style scoped>
.scrolled {
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-bottom: 0px;
  background-color: red;
}
.sticky {
  position: fixed;
  background-color: rgb(255 255 255);
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}
</style>
