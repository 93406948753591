<template>
  <div id="feature" class="py-24 bg-[#f3f4f6]">
    <div class="w-4/5 mx-auto">
      <div class="container">
        <div class="text-center">
          <h2
            class="mb-12 section-heading wow fadeInDown"
            data-wow-delay="0.3s"
          >
            Our Features
          </h2>
        </div>
        <div class="flex flex-wrap items-center">
          <div
            class="w-full sm:w-full md:w-full lg:w-1/2 rounded-xl"
            v-for="feature in features"
            :key="feature.id"
          >
            <div
              class="flex p-10 m-4 bg-blue-100 rounded-lg wow fadeInRight hover:bg-blue-100"
              data-wow-delay="0.3s "
            >
              <div class="flex flex-col w-2/3">
                <div class="flex">
                  <h3 class="mb-2 text-2xl font-medium">
                    {{ feature.attributes.title }}
                  </h3>
                </div>
                <div class="flex justify-center">
                  <p class="text-gray-600"></p>
                  <ul>
                    <li
                      class="flex items-center py-1 mt-2 space-x-2 text-gray-600"
                      v-for="service in feature.attributes.service"
                      :key="service.id"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="flex-shrink-0 w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>

                      <!-- <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="flex-shrink-0 w-6 h-6"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                        />
                      </svg> -->

                      <span class="text-sm"> {{ service.feature }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="flex items-center w-1/3">
                <img
                  class="mx-auto"
                  :alt="feature.attributes.title"
                  :src="
                    getBaseUrl() + feature.attributes.image.data.attributes.url
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../../Api";
import { mapState, mapActions } from "vuex";

export default {
  name: "features-page",
  created() {
    this.fetchFeatures();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchFeatures"]),
  },
  computed: {
    ...mapState(["features"]),
  },
};
</script>

<style lang="scss" scoped></style>
