<template>
  <footer id="footer" class="py-16 bg-gray-800">
    <div class="container">
      <div class="flex flex-wrap">
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.2s"
        >
          <div class="mx-3 mb-8">
            <div class="mb-3 footer-logo">
              <img src="assets/img/logo.svg" alt="" />
            </div>
            <p class="text-gray-200">
              <VueShowdown :markdown="`${address.attributes.content}`" />
            </p>
          </div>
        </div>
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.4s"
        >
          <div class="mx-3 mb-8">
            <h3 class="mb-5 text-xl font-bold text-white">Company</h3>
            <ul>
              <li><a href="#" class="footer-links">Press Releases</a></li>
              <li><a href="#" class="footer-links">Mission</a></li>
              <li><a href="#" class="footer-links">Strategy</a></li>
            </ul>
          </div>
        </div>
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.6s"
        >
          <div class="mx-3 mb-8">
            <h3 class="mb-5 text-xl font-bold text-white">About</h3>
            <ul>
              <li><a href="#" class="footer-links">Career</a></li>
              <li><a href="#" class="footer-links">Team</a></li>
              <li><a href="#" class="footer-links">Clients</a></li>
            </ul>
          </div>
        </div>
        <div
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/4 wow fadeInUp"
          data-wow-delay="0.8s"
        >
          <div class="mx-3 mb-8">
            <h3 class="mb-5 text-xl font-bold text-white">Find us on</h3>

            <ul class="flex items-center">
              <li class="mx-2" v-for="s in social" :key="s.id">
                <a
                  :href="s.attributes.link"
                  class="cursor-pointer footer-icon"
                  target="_blank"
                >
                  <img
                    class="w-6 h-6"
                    :alt="s.url"
                    :src="getBaseUrl() + s.attributes.icon.data.attributes.url"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <div class="text-sm">
          <router-link
            to="/legal/imprint"
            target="_blank"
            class="px-2 py-1 text-gray-400 hover:text-gray-300"
          >
            Terms of use
          </router-link>
          <span class="mx-1 text-gray-400">|</span>
          <router-link
            to="/legal/privacy"
            target="_blank"
            class="px-2 py-1 text-gray-400 hover:text-gray-300"
          >
            Privacy policy
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { baseUrl } from "../../Api";
import { mapState, mapActions } from "vuex";

export default {
  name: "footer-part",
  created() {
    this.fetchAddress();
    this.fetchSocial();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchAddress", "fetchSocial"]),
  },
  computed: {
    ...mapState(["address", "social"]),
  },
};
</script>

<style></style>
