import axios from "axios";
import { baseUrl } from "../../Api";

// A single Axios instance for our entire app
const apiClient = axios.create({
  // Base URL for all calls to use
  baseURL: baseUrl,
  withCredentials: false,
  // For authentication and configuration
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

export default {
  getHero() {
    return apiClient.get(`api/hero?populate=*`);
  },
  getSupport() {
    return apiClient.get(`api/supports?populate=*`);
  },
  getServices() {
    return apiClient.get(`api/services?populate=*`);
  },
  getFeatures() {
    return apiClient.get(`api/features?populate=*`);
  },
  getPartners() {
    return apiClient.get(`api/partners?populate=*`);
  },
  getAddress() {
    return apiClient.get(`api/address`);
  },
  getSocials() {
    return apiClient.get(`api/socials?populate=*`);
  },
  getTermsOfUse() {
    return apiClient.get(`api/terms-of-use`);
  },
  getPrivacyPolicy() {
    return apiClient.get(`api/privacy-policy`);
  },
};
