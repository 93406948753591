<template>
  <div
    v-if="show"
    class="fixed top-0 left-0 z-50 flex items-center justify-center w-full h-screen bg-black bg-opacity-70"
  >
    <div
      @click.self="close_modal()"
      class="absolute w-full h-full opacity-50 modal-overlay"
    ></div>
    <div class="relative mx-auto w-full max-w-[750px] bg-white">
      <div class="absolute z-40 top-4 left-4">
        <span
          class="px-4 text-lg font-medium leading-snug text-transparent bg-clip-text bg-gradient-to-r from-pink-500 to-violet-500"
        >
          {{ video.attributes.title }}
        </span>
      </div>
      <video controls class="w-full h-full">
        <source
          :src="getBaseUrl() + video.attributes.video.data.attributes.url"
          type="video/mp4"
        />
      </video>
    </div>
    <!-- @click="videoOpen = false" -->
    <button
      @click="close_modal()"
      class="absolute top-0 right-0 flex items-center justify-center w-20 h-20 text-white cursor-pointer hover:bg-primary-gold"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="fill-current w-9 h-9"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M6 18L18 6M6 6l12 12"
        />
      </svg>
    </button>
  </div>
</template>

<script>
import { baseUrl } from "../../Api";

export default {
  props: {
    show: Boolean,
    video: null,
  },
  name: "VideoPage",
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    close_modal() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped></style>
