<template>
  <div class="max-w-4xl px-5 py-20 mx-auto text-body-color">
    <div class="text-sm legals">
      <VueShowdown :markdown="`${privacy_policy.attributes.content}`" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SectionPrivacy",
  created() {
    this.fetchPrivacyPolicy();
  },
  methods: {
    ...mapActions(["fetchPrivacyPolicy"]),
  },
  computed: {
    ...mapState(["privacy_policy"]),
  },
  watch: {
    async lang() {
      await this.fetchPrivacyPolicy();
    },
  },
};
</script>

<style scoped></style>
