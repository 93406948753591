<template>
  <div>
    <div class="top-0 z-50 border-b backdrop-blur-md">
      <div
        class="flex flex-wrap items-center justify-between px-5 py-1 mx-auto max-w-7xl"
      >
        <router-link to="/">
          <div class="py-5">
            <a href="/" class="nav-item">
              <img src="../assets/logo.png" alt="habariDoc logo" class="w-28" />
            </a>
          </div>
        </router-link>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
