import Vue from "vue";
import Vuex from "vuex";
import DataService from "@/components/service/DataServices";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    hero: [],
    services: [],
    features: [],
    partners: [],
    supports: [],
    address: [],
    social: [],
    privacy_policy: [],
    terms_of_use: [],
  },
  getters: {},
  mutations: {
    SET_HERO(state, hero) {
      state.hero = hero;
    },
    SET_SUPPORT(state, supports) {
      state.supports = supports;
    },
    SET_SERVICES(state, services) {
      state.services = services;
    },
    SET_FEATURES(state, features) {
      state.features = features;
    },
    SET_PARTNERS(state, partners) {
      state.partners = partners;
    },
    SET_ADDRESS(state, address) {
      state.address = address;
    },
    SET_SOCIAL(state, social) {
      state.social = social;
    },
    SET_PRIVACY_POLICY(state, privacy_policy) {
      state.privacy_policy = privacy_policy;
    },
    SET_TERMS_OF_USE(state, terms_of_use) {
      state.terms_of_use = terms_of_use;
    },
  },
  actions: {
    fetchHero({ commit }) {
      DataService.getHero()
        .then((response) => {
          commit("SET_HERO", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching hero:" + error.message);
        });
    },
    fetchSupport({ commit }) {
      DataService.getSupport()
        .then((response) => {
          commit("SET_SUPPORT", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching support:" + error.message);
        });
    },
    fetchServices({ commit }) {
      DataService.getServices()
        .then((response) => {
          commit("SET_SERVICES", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching serviecs:" + error.message);
        });
    },
    fetchFeatures({ commit }) {
      DataService.getFeatures()
        .then((response) => {
          commit("SET_FEATURES", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching features:" + error.message);
        });
    },
    fetchTermsOfUse({ commit }) {
      DataService.getTermsOfUse()
        .then((response) => {
          console.log("terms of use", response.data.data);
          commit("SET_TERMS_OF_USE", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching terms of use:" + error.message
          );
        });
    },
    fetchPrivacyPolicy({ commit }) {
      DataService.getPrivacyPolicy()
        .then((response) => {
          console.log("privacy policy", response.data.data);
          commit("SET_PRIVACY_POLICY", response.data.data);
        })
        .catch((error) => {
          console.log(
            "There was a problem fetching privacy policy:" + error.message
          );
        });
    },
    fetchPartners({ commit }) {
      DataService.getPartners()
        .then((response) => {
          commit("SET_PARTNERS", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching partners:" + error.message);
        });
    },
    fetchAddress({ commit }) {
      DataService.getAddress()
        .then((response) => {
          commit("SET_ADDRESS", response.data.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching address:" + error.message);
        });
    },
    fetchSocial({ commit }) {
      DataService.getSocials()
        .then((response) => {
          commit("SET_SOCIAL", response.data.data);
          console.log("socials", response.data);
        })
        .catch((error) => {
          console.log("There was a problem fetching socials:" + error.message);
        });
    },
  },
  modules: {},
});
