<template>
  <div class="home">
    <nav-bar />
    <hero-page />
    <service-page />
    <features-page />
    <partners-page />
    <support-page />
    <my-footer />
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/navbar/index.vue";
import HeroPage from "@/components/hero/HeroPage.vue";
import ServicePage from "@/components/services/ServicePage.vue";
import FeaturesPage from "@/components/features/FeaturesPage.vue";
import PartnersPage from "@/components/partners/PartnersPage.vue";
import SupportPage from "@/components/support/SupportPage.vue";
import MyFooter from "@/components/footer/footer.vue";

export default {
  name: "HomeView",
  components: {
    NavBar,
    HeroPage,
    ServicePage,
    FeaturesPage,
    PartnersPage,
    SupportPage,
    MyFooter,
  },
};
</script>
