<template>
  <div id="partner" class="py-24">
    <div class="mx-auto">
      <div class="container">
        <div class="text-center">
          <h2
            class="mb-12 section-heading wow fadeInDown"
            data-wow-delay="0.3s"
          >
            Our Partners
          </h2>
        </div>
        <div class="flex flex-wrap items-center">
          <div
            class="w-full sm:w-1/2 md:w-1/2 lg:w-1/5"
            v-for="partner in partners"
            :key="partner.id"
          >
            <a :href="partner.attributes.url" target="_blank">
              <div class="m-4 wow fadeInRight" data-wow-delay="0.3s">
                <div class="flex items-center py-6">
                  <img
                    class="mx-auto h-[54px]"
                    alt="partners"
                    :src="
                      getBaseUrl() +
                      partner.attributes.image.data.attributes.url
                    "
                  />
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseUrl } from "../../Api";
import { mapState, mapActions } from "vuex";

export default {
  name: "partners-page",
  created() {
    this.fetchPartners();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchPartners"]),
  },
  computed: {
    ...mapState(["partners"]),
  },
};
</script>

<style lang="scss" scoped></style>
