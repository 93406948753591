<template>
  <section id="services" class="w-4/5 py-16 mx-auto md:py-12">
    <div class="container">
      <div class="text-center">
        <h2 class="mb-12 section-heading wow fadeInDown" data-wow-delay="0.3s">
          Our Services
        </h2>
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="service in services"
          :key="service.id"
          class="w-full sm:w-1/2 md:w-1/2 lg:w-1/3 rounded-xl hover:bg-[#f3f4f6]"
        >
          <div class="m-4 wow fadeInRight" data-wow-delay="0.3s">
            <div class="flex items-center py-6">
              <img
                class="w-24 h-24 mx-auto"
                :alt="service.attributes.title"
                :src="
                  getBaseUrl() + service.attributes.image.data.attributes.url
                "
              />
            </div>
            <div>
              <h2 class="mb-2 text-xl font-semibold text-center text-gray-900">
                {{ service.attributes.title }}
              </h2>
              <p class="mb-4 text-base text-gray-600">
                <VueShowdown :markdown="`${service.attributes.content}`" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { baseUrl } from "../../Api";
import { mapState, mapActions } from "vuex";

export default {
  name: "services-page",
  created() {
    this.fetchServices();
  },
  methods: {
    getBaseUrl() {
      return baseUrl;
    },
    ...mapActions(["fetchServices"]),
  },
  computed: {
    ...mapState(["services"]),
  },
};
</script>

<style></style>
