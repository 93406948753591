var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"bg-[#f3f4f6]"},[_c('div',{staticClass:"fixed top-0 left-0 z-30 w-full duration-300 navigation",class:{
      sticky: !_vm.view.atTopOfPage,
    }},[_c('div',{staticClass:"container"},[_c('nav',{staticClass:"relative flex items-center justify-between py-4 duration-300 md:py-2"},[_vm._m(0),_c('div',{staticClass:"lg:hidden"},[_c('svg',{staticClass:"w-8 h-8 text-gray-600 hover:text-gray-400",attrs:{"xmlns":"http://www.w3.org/2000/svg","fill":"none","viewBox":"0 0 24 24","stroke-width":"1.5","stroke":"currentColor"},on:{"click":_vm.toggleView}},[(!_vm.isOpen)?_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"}}):_vm._e(),(_vm.isOpen)?_c('path',{attrs:{"stroke-linecap":"round","stroke-linejoin":"round","d":"M6 18L18 6M6 6l12 12"}}):_vm._e()])]),_vm._m(1),_vm._m(2)]),_c('div',{staticClass:"absolute left-0 z-20 w-full px-10 py-3 duration-300 bg-white shadow lg:hidden mt-full lg:static",class:_vm.isOpen ? 'block' : 'hidden'},[_vm._m(3)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-item",attrs:{"href":"/"}},[_c('img',{staticClass:"w-28",attrs:{"src":require("../../assets/logo.png"),"alt":"habariDoc logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"left-0 z-20 hidden w-full px-5 py-3 duration-300 bg-white shadow lg:block mt-full lg:static lg:bg-transparent lg:shadow-none"},[_c('ul',{staticClass:"items-center justify-center mr-auto lg:flex"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll active",attrs:{"href":"#hero-area"}},[_vm._v("Home")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll",attrs:{"href":"#services"}},[_vm._v("Services")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll",attrs:{"href":"#feature"}},[_vm._v("Features")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll",attrs:{"href":"#partner"}},[_vm._v("Partners")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll",attrs:{"href":"#support"}},[_vm._v("Support")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hidden space-x-5 header-btn lg:block"},[_c('a',{staticClass:"px-10 py-3 text-blue-600 duration-300 border border-blue-600 rounded-full hover:bg-blue-600 hover:text-white",attrs:{"href":"#feature"}},[_vm._v(" Login ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"items-center justify-center mr-auto lg:flex"},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll active",attrs:{"href":"#hero-area"}},[_vm._v("Home")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll",attrs:{"href":"#services"}},[_vm._v("Services")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll",attrs:{"href":"#feature"}},[_vm._v("Features")])]),_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"page-scroll",attrs:{"href":"#partner"}},[_vm._v("Partners")])])])
}]

export { render, staticRenderFns }