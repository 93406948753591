<template>
  <div class="max-w-4xl px-5 py-20 mx-auto text-body-color">
    <div class="text-sm legals">
      <VueShowdown :markdown="`${terms_of_use.attributes.content}`" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "SectionImprint",
  created() {
    this.fetchTermsOfUse();
  },
  methods: {
    ...mapActions(["fetchTermsOfUse"]),
  },
  computed: {
    // about: (state) => state.about,
    ...mapState(["terms_of_use"]),
  },
  watch: {
    async lang() {
      await this.fetchTermsOfUse();
    },
  },
};
</script>

<style lang="scss" scoped></style>
